import { render, staticRenderFns } from "./DevAuth.vue?vue&type=template&id=220cee02&scoped=true&"
import script from "./DevAuth.vue?vue&type=script&lang=js&"
export * from "./DevAuth.vue?vue&type=script&lang=js&"
import style0 from "./DevAuth.vue?vue&type=style&index=0&id=220cee02&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220cee02",
  null
  
)

export default component.exports